.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px 52px 0 100px;
}
.RightBtns {
}
.login,
.signup {
  box-shadow: 0px 10px 30px 0px #4e91e64d;
  background: #45cd84;
  border-radius: 50px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.05em;
  padding: 7px 24px;
  text-align: center;
  display: flex;
}

.items span {
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}
.mobile-navbar {
  display: none;
}
.mobile-navbar .menuBar {
  display: flex;
  justify-content: flex-end;
  padding: 20px 10px;
}
.mobile-navbar .menuBar img {
  display: flex;
}
.mobile__description {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.mobile__description img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 30px 0;
}
.getStarted {
  display: flex;
  justify-content: center;
  margin: 0 35%;
  padding: 7px;
  cursor: pointer;
}
.mobile-title {
  color: #54e87d;
  font-size: 20px;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0.085em;
  text-align: left;
  padding: 0 15%;
}
.mobile-subtitle {
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.085em;
  text-align: left;
  color: white;
  padding: 0 15%;
}
.getStarted {
  display: flex;
  align-items: center;
  color: white;
  background: #45cd84cf;
  box-shadow: 0px 10px 30px 0px #4e91e64d;
  border-radius: 50px;
}
.getStarted__title {
  font-size: 9px;
  font-weight: 800;
  line-height: 14px;
  letter-spacing: 0.085em;
  text-align: left;
  color: white;
}
.mobile_menu {
  display: flex;
  flex-direction: column;
  gap: 80px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.mobile_menu span {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}
