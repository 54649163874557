@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@font-face{
  font-family: Poppins;
  src: url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
}
@font-face{
  font-family: Inter;
  src: url("https://rsms.me/inter/inter.css");
}
body{
  height: 100vh;
}
#root{
  background: linear-gradient(150.48deg, rgba(0, 253, 131, 0.15) -4.58%, rgba(17, 31, 27, 0.15) 53.15%) #070510;

}
.Index{
  background: linear-gradient(150.48deg, rgba(0, 253, 131, 0.15) -4.58%, rgba(17, 31, 27, 0.15) 53.15%) #070510;
}

button{
  outline: none;
  border: none;
  cursor: pointer;
}
img{
    max-width: 100%;
}
a{
  text-decoration: none;
}
/* top part */
.top{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 122px;
  /*background: url("./assets/img/wisewager 1.png") no-repeat bottom center ;*/
  /*height: 547px;*/
  /*background-position-y: 100px;*/
  position: relative;

}
.topLogo{
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: center;
  margin: 0 auto;
}
.title{
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.49em;
  color: #1DFFA0;
}
.subtitle{
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;
  color: #A6A6A6;
  padding-top: 20px;
}
.join{
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #131315;
  background: #45CD84;
  border-radius: 32px;
  padding: 3px 20px;
  cursor: pointer;
}
.Join{
  border: 1px solid #00FF8512;
  margin: 0 auto;
  padding: 3px;
  box-shadow: 2px 8px 18px 0px #00000061;
  background: #70707017;
  border-radius: 45px;
}

/* service part*/

.Service{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 123px;

}
.service__title{
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
}
.Service .sportsIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 136px;
}
.sportsIcon img{
  width: 97%;
  max-width: 100%;
}
.Service .description{
  background: #A0A0A00D;
  border: 1px solid #DFD4D466;
  box-shadow: 10px 14px 20px 0px #00000017;
  border-radius: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
    margin: 0 27%;

}
.description img{
  width: 17%;
  max-width: 100%;
}
.des__title{
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  padding-right: 40px;

}
.BottomPart{
  display: flex;
    flex-wrap: wrap;

    align-items: center;
  justify-content: space-between;
  gap: 50px;
  margin-top: 165px;
}
.Guide__machine__start{
  display: flex;
  flex-direction: column;
  gap: 23px;
}
.guide__support,.start__wining__today,.machine__learning{
  display: flex;
  align-items: center;
  gap: 21px;
  border: 1px solid #FFFFFF0D;
  background: #FFFFFF05;
  padding: 10px 52px 20px 20px;
  border-radius: 18px;

}
.guide_description{
  display: flex;
  flex-direction: column;
}
.guide__subtitle{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #B6B6B6;
}
.guide__title{
  color: #ECF1F0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: left;

}

#ourMission{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 122px;
}
.ourMission .MissionTitle{
  font-size: 45px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
}
.Mission__content{
  display: flex;
  flex-direction: column;
  gap: 58px;
  margin-top: 70px;
}
.first,.second,.third{
  display: flex;
  align-items: center;
  gap: 65px;
}
.mission__description{
  font-size: 26px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: white;

}

.Ask__Question{
  margin-top: 83px;
  margin-bottom: 137px;
  display: flex;
  flex-direction: column;
  gap: 23px;
}
.Accordion{
  background: #FFFFFF05!important;
  border: 2px solid #00FFA30D;
  box-shadow: 0px 6px 16px 0px #26262630;
  border-radius: 14px!important;
  padding: 35px 39px;
  color: white!important;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0 27%!important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
  background: #FFFFFF05!important;
  border: 2px solid #00FFA30D;
  box-shadow: 0px 6px 16px 0px #26262630;
  border-radius: 14px!important;
  padding: 35px 39px;
  color: white!important;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0 27%!important;
}
.headerTitle{
  font-size: 22px!important;
  font-weight: 600!important;
  line-height: 28px!important;
  letter-spacing: 0px!important;
  text-align: left;

}
.details{
  font-size: 18px!important;
  font-weight: 400!important;
  line-height: 30px!important;
  letter-spacing: 0em!important;
  text-align: left;

}

/*faq end*/
.membership{
  background: linear-gradient(180.57deg, rgba(0, 255, 102, 0.06) 0.5%, rgba(90, 90, 90, 0.06) 101.44%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: solid 2px transparent;
  border-bottom: solid 2px transparent;
  background-clip: padding-box;
  box-sizing: border-box;
  border-image-slice: 1;
  border-image-source: linear-gradient(153.67deg, rgba(0, 255, 102, 0.37) -12.62%, rgba(9, 9, 9, 0.37) 130.16%);
}
.membership__title{
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  padding: 27px 0 11px;
}
.pay__perMonth{
  display: flex;
  align-items: center;
  justify-content: center;
}
.price{
  color: #63E3AD;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}
.month{
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(99, 227, 173, 0.5);

}
.divider{
  border: 1px solid #3F3F3F;
  width: 100%;
  margin: 20px 0 30px 0;
}
.member__description{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.firstDescription,.secondDescription,.thirdDescription{
  display: flex;
  align-items: center;
  gap: 7px;
}
.fullAccess{
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #AEAEAE;
}
.choosePlan{
  background: linear-gradient(131.26deg, #205E40 -29.57%, rgba(32, 94, 64, 0) 93.59%);
  color: white;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 15px;
  justify-content: center;
  margin: 45px auto 29px auto;
}

.Subscribe{
  margin: 54px 24px 9px 24px;
  background: #02020212;
  padding: 48px 167px;
  display: flex;
  align-items: center;
  gap: 77px;
  justify-content: space-between;

}
.signup__newletter{
  display: flex;
  flex-direction: column;
}
.subscribe{
  display: flex;
  gap: 35px;
}
.newletter__title{
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: white;

}
.jonNow{
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #FFFFFF;

}
.subscribe input{
  background: transparent;
  border-bottom: 1px solid #FFFFFF66!important;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: left;
  width: 374px;
  outline: none;
  color: white;
}
input::placeholder{
  color: white;
}
.subscribe__btn{
  background: #45CD84;
  border-radius: 32px;
  color: #0D0D2B;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}

.footer{
  display: flex;
  align-items: center;
  padding: 61px 90px;
  gap: 240px;
  justify-content: space-between;
}
.footer__logo{
  color: #0FAE96;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 65px;

}
.footerLogo{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 352px;
}
.social{
  display: flex;
  align-items: center;
  gap: 14px;
}
.copyright{
  color: #B6B6B6;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
}
.footerRight{
  display: flex;
  align-items: center;
  gap: 139px;
  color: white;
  justify-content: center;
  width: calc(100% - 352px);
}
.footerRight a{
  cursor: pointer;
  color: white;
}
.footerRight span{
  cursor: pointer;
}
.Contacts{
  background: url("./assets/img/contact.png") no-repeat center;
  background-size: 70%;
  padding: 40px 0 0 0;

}
.contact{
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 96px 0;

}
.contact__title{
  font-size: 60px;
  font-weight: 600;
  line-height: 74px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}
.contact__body{
  /*background: #164E364F;*/
  /*border-radius: 40px;*/
  margin: 0 26%;
}
.contactBody{
  display: flex;
  border: 1.5px solid transparent;
  background: linear-gradient(116.5deg, #FFFFFF 6.1%, #000000 95.45%);
  padding: 66px 38px;
  border-radius: 40px;
  -webkit-mask: /*4*/
          linear-gradient(116.5deg, #FFFFFF 6.1%, #000000 95.45%) padding-box,
          linear-gradient(116.5deg, #FFFFFF 6.1%, #000000 95.45%);
  color: white;
  background: #164E364F;
  gap: 68px;
}
.contact__left{
  display: flex;
  flex-direction: column;
  gap: 23px;
}
.question{
  color: white;
  font-size: 25px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}
.questionDes{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: white;

}
.contact__right .question{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.contact__form,.contact__right{
  max-width: 100%;
  width: 340px;

  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact__form input{
  background: #FFFFFF4D;
  box-shadow: 0px 10px 20px 0px #060E32;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  border: none;
  outline: none;
}
.contact__form textarea{
  background: #FFFFFF4D;
  box-shadow: 0px 10px 20px 0px #060E32;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  border: none;
  outline: none;
  resize: none;
  height: 200px;
}
textarea::placeholder{
  color: white;
}
.sendMessageBtn{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 11px;
  background: linear-gradient(105.61deg, rgba(16, 185, 94, 0.45) 31.65%, rgba(199, 199, 199, 0.45) 91.12%),
  linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  box-shadow: 0px 10px 20px 0px #060E32;
  border-radius: 10px;
  margin:0 auto;
  color: white;

}
@media only screen and (max-width: 1440px) {
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root{
    margin: 0 23%!important;
  }
  .Accordion{
    margin: 0 23%!important;
  }
  .contact__body{
    margin: 0 18% !important;
  }
}
@media only screen and (max-width: 1280px) {
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root{
    margin: 0 19%!important;
  }
  .Accordion{
    margin: 0 19%!important;  }
}

@media only screen and (max-width: 414px) {
    .top{
        margin-top: 40px!important;
    }
    .mobile-navbar{
        display: block!important;
    }
    .Service .description {
        margin: 0 2% !important;
    }
    .Service .description .des__title{
        padding: 19px 10px 19px 0!important;
    }
    .Guide__machine__start{
        margin: 0 2% !important;
    }
    .guide__support,.start__wining__today,.machine__learning {
        padding: 10px 20px 20px 20px!important;
    }
    .guide__subtitle{
        font-size: 14px!important;
    }
    #ourMission{
        margin-top: 80px!important;
    }
    .Mission__content .first, .second, .third{
        gap: 9px!important;
    }
    .mission__description{
        font-size: 20px!important;
    }
    .Mission__content img{
        width: 16% !important;
    }
    .Accordion{
        margin: 0 2% !important;
        padding: 10px 0px!important;
    }
    .headerTitle{
        font-size: 20px!important;
        font-weight: 600!important;
        line-height: 28px!important;
        letter-spacing: 0px!important;
        text-align: left;
    }
    .contactBody{
      flex-wrap: wrap!important;
      margin: 0 2% !important;

    }
    .Subscribe{
        flex-wrap: wrap!important;
        padding: 0!important;
    }
    .subscribe{
        width: 100% !important;
        flex-direction: column!important;
        gap: 15px;
    }
    .subscribe input{
        width: inherit!important;
        width: -webkit-fill-available!important;
    ;
        border-bottom: 1px solid #FFFFFF66!important;
    }
    .subscribe__btn{
        padding: 6px;
    }
    .footer{
        padding: 40px 15px!important;
        flex-wrap: wrap!important;
        gap: 60px!important;
    }
    .footerLogo{
        width: -webkit-fill-available!important;
    }
    .footerRight{
        gap: 60px!important;
        width: 100%;
    }
}